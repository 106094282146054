import React from 'react'
import Layout from '../../components/Layout/Layout'
import {Link} from "gatsby";
import './404.scss'

const NotFoundPage = () => (
  <Layout  title="404">
    <div className="page404">
      <h1>Sorry, this page isn't available.</h1>
      <p>The link you followed may be broken, or the page may have been removed.</p>
      <Link to="/">
        Go back to homepage
      </Link>
    </div>
  </Layout>
);

export default NotFoundPage
